
.project {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: left 300ms, right 300ms;
    min-height: 100vh;
}

.project.moveRight {
    left: 100vw;
    right: -100vw;
}

.editProject {
    position: absolute;
    top: 0;
    left: -100vw;
    right: 100vw;
    bottom: 0;
    background-color: var(--main-lightest);
}

.adminLabel {
    display: block;
    font-size: 1rem;
    padding-top: .65rem;
}

.task .points {
    display: inline-block;
    margin-left: 5px;
    font-size: 1.2rem;
}

.listItem .points svg {
    height: 35px;
    width: 35px;
    margin-left: 5px;
}
