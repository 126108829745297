.worker {
    --time: 200ms;
    position: absolute;
    transition:
            border-radius var(--time) ease-out,
            top var(--time) ease-out,
            left var(--time) ease-out,
            right var(--time) ease-out,
            bottom var(--time) ease-out;
    z-index: 101;
}

.worker span {
    transition: opacity var(--time) ease-out,
}

.worker span.faded {
    opacity: 0;
}

.worker svg.point,
.worker.minimized svg.point {
    height: 2rem;
    width: 2rem;
    margin-left: .5rem;
    margin-bottom: -9px;
}


.worker.minimized {
    top: calc(100vh - 60px);
    left: 10px;
    right: 10px;
    bottom: 10px;
}

.footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    height: 50px;

    padding: 2px 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}


.worker.expanded {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 50px;
}

.worker .scroll {
    height: calc(100vh - 200px);
}


@media (max-width: 360px) {
    .worker.minimized svg {
        height: 28px;
        width: 28px;
    }

}