.input {
    background-color: rgba(0,0,0,0);color: inherit;
    padding: 3px !important;
    margin: 0;
    font-size: inherit;
}

.secretInput {
    border: none !important;
    width: 70px;
}

.main .input {
    border-color: var(--contrast)
}


.taskHeader {
    margin-bottom: -8px;
}

.taskHeader .label {
    text-align: left;
}

.editing .taskHeader textarea,
.editing .secretInput {
    border: 1px solid var(--contrast) !important;
}

.taskHeader textarea {
    width: calc(100vw - 185px);
    height: 25px;
    margin-top: 2px;
}

.editing .taskHeader textarea {
    height: 56px;
}

.editableTask .smallMargin {
    width: 50px;
    margin: 0 5px;
}


.editWorker {

}

.secretInput.mail {
    width: calc(100vw - 160px);
}

.secretInput.name {
    width: calc(100vw - 153px);
}

.deleting path {
    fill: red !important;
}