.App {
  --main-darker: #2c0044;
  --main-dark: #45006c;
  --main: #66009c;
  --main-light: #9200e0;
  --main-lighter: #bb57ff;
  --main-lightest: #e7c8ff;
  --contrast: white;

  --main-faded: #66009c50;

  --highlight: #00e0be;
  --danger: #e00047;
}

.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;


  font-size: 1.5rem;

  color: var(--main);
}

.appContent {
  /*padding: 20px;*/
}

.touchOutsideSensor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

button {
  background-color: rgba(0,0,0,0);
  border: none;
  /*border: 1px solid rgba(0,0,0,0);*/
  outline: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  -webkit-tap-highlight-color: transparent;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

textarea {
  /*resize:none*/
}
textarea::-webkit-scrollbar {
  display: none;
}

textarea,
input[type=text],
input[type=text-area],
input[type=number] {
  padding: 6px;
  border: 1px solid var(--main);
  border-radius: 5px;
  font-size: 1.2rem;
  font-family: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.button {
  font-family: inherit;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1.5rem;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.button.large {
  font-size: 2rem;
  margin-top: 10px;
}

.rounded10 {
  border-radius: 10px;
}

.rounded20 {
  border-radius: 20px;
}

/*.contrast svg:not(.point) path {*/
.contrast svg:not(.point) path {
  fill: var(--main)
}

svg.contrast path {
  fill: var(--contrast)
}

.main {
  background-color: var(--main);
  color: var(--contrast);
}

.main:active {
  background-color: var(--main-dark);
}

.button.contrast {
  color: var(--main);
  background-color: var(--contrast);
}


.contrast:not(svg) {
  background-color: var(--contrast);
  color: var(--main);
}


.main svg {
  fill: var(--contrast);
}

.button.danger {
  -webkit-box-shadow: 0px 0px 15px 5px var(--danger);
  box-shadow: 0px 0px 15px 5px var(--danger);
  color: var(--danger)
}

.button.safe {
  -webkit-box-shadow: 0px 0px 15px 5px var(--highlight);
  box-shadow: 0px 0px 15px 5px var(--highlight);
}

.main a {
  color: var(--contrast)
}

.roundButton {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 100%;
  padding: 0;
}

.roundButton.svgButton {
  padding: 7.5px
}

.addButton {
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton {
  height: 70px;
  width: 70px;
  font-weight: bold;
  font-size: 4rem;
}

.addButton:after {
  content: '+';
  margin-top: -6px;
  font-size: 4rem;
}

.fullWidth {
  width: 100%;
  max-width: calc(100vw - 34px);
}

.listItem .fullWidth {
  max-width: calc(100vw - 60px);
}

.fullHeight {
  min-height: 100vh;
}

.block {
  display: block;
}

.listItem {
  padding: 1rem;
  margin: .5rem 10px;
  border-radius: 5px;
}

.faded {
  opacity: .6;
}

/*TEXT*/
.minitext {
  font-size: .8rem;
}

.mediumText {
  font-size: 1rem;
}

.noWrap {
  white-space: nowrap;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.text1 {
  font-size: 1rem;
  padding-top: .4rem;
}

/*PADDING AND MARGIN*/

.marginAuto {
  margin: auto;
}

.marginTop4rem {
  margin-top: 4rem;
}

.marginTop10 {
  margin-top: 10px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.padding10 {
  padding: 10px
}

.paddingTop10 {
  padding-top: 10px
}

.paddingBottom10 {
  padding-bottom: 10px
}

.padding20 {
  padding: 20px
}

.scroll {
  overflow-y: auto;
}

.fadeWrapper {
  position: relative;
  /*padding: 2rem 0;*/
}

.fadeWrapper:before {
  content  : "";
  position : absolute;
  z-index  : 1;
  top   : 0;
  left     : 0;
  right: 0;
  pointer-events   : none;
  background-image : linear-gradient(to top,
    rgba(255,255,255, 0),
    var(--main) 100%);
  height   : 1em;
}

.fadeWrapper:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  right: 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom,
    rgba(255,255,255, 0),
    var(--main) 100%);
  height   : 1em;
}


.inline-block {
  display: inline-block;
}

/*FLEXING*/

.flex {
  display: flex;
}

.flex.center {
  align-items: center;
  justify-content: center;
}

.flex.spaceBetween {
  justify-content: space-between;
}

.flex.start {
  align-items: flex-start;
}

.flex.column {
  flex-direction: column;
}

.flex.row {
  flex-direction: row;
}

.space {
  margin: 10px;
}

.z100 {
  z-index: 100;
}

/*FIXING*/

.abs {
  position: absolute;
}

.fix {
  position: fixed;
}

.top10 {
  top: 10px;
}

.left10 {
  left: 10px;
}

.right10 {
  right: 10px;
}

.bottom10 {
  bottom: 10px;
}

.App > svg {
  --size: min(90vw, 90vh);
  width: var(--size);
  height: var(--size);
}


@media (max-width: 360px) {
  .App,
  .button {
    font-size: 1.2rem;
  }

  .mediumText {
    font-size: 1rem;
  }
}